
:root {
  --primary-color: #1B0B49;
  --text-primary: #222;
  --base00: #fefff5;
  --base01: #FCFBF1;
  --base02: #F5F3E7;
  --surface-primary: #FCFBF1;
  --surface-lavender: #ECEBFF;
  --surface-wasabi: #EAF2D2;
  --surface-lavender-00: #EDF1FC;
  --surface-wasabi-00: #eef2e3;
}

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');




html{
  margin:0
}

body{
  background-color: var(--base00);
  margin:0 0 144px 0;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
}

p{
  margin-block-end:0.5em;
  margin-block-start:0.5em;

}


.container {
  max-width: 1200px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  
}



.projectSection {
  max-width: 760px;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 20px;
  padding-top: 72px;
  padding-bottom: 48px;
  padding-left: 24px;
  padding-right: 24px;
}


body p {
  font-family: 'Manrope';
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: 0.6px;
  color: #222;
  font-weight: 500;
  

}

a {
  text-decoration: none;
  font-family: 'Manrope';
  color:#222

}
h1 {
  color: var(--text-primary);
  font-family: 'Manrope';
  font-size: 26px;
  line-height: 42px;
  font-weight: 600;
  letter-spacing: 1px;
  margin:0;
}

h2 {
  color: var(--text-primary);
  font-family: 'Manrope';
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: 1px;
  margin:0;
  
}

h3 {
  color: var(--text-primary);
  font-family: 'Manrope';
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 12px;
  margin:0;
}


.display1 {
  color: var(--text-primary);
  font-family: 'Manrope';
  font-size: 34px;
  line-height: 150%;
  font-weight: 600;
  letter-spacing: 1px;

}

.display2 {
  color: var(--primary-color);
  font-family: 'Manrope';
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 1px;

}

.p-medium {
  font-family: 'Manrope';
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;

  

}

.cardParent {
  border-radius: 32px;
  padding: 24px;
  transition: transform 0.3s;
}

.cardParent:hover {
  transform: scale(1.01);
}

.cardParent:hover .backgroundShape {
  transform: rotate(15deg);
}

.backgroundShape {
  width: 50%;
  position: absolute;
  transition: transform 0.2s;

}

.heroImage {
  width: 60%;
  position: relative;
  object-fit: contain;

}






.cardTop {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  aspect-ratio: 1.5;
  margin-bottom: 16px;
}

.cardDescription {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  background-color: white;
  border-radius: 32px;
  padding: 24px 30px 24px 30px;
  gap: 0px;

}

.projectsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
  gap: 32px;
}

#about {
  margin-bottom: 80px;
  margin-top: 32px;
}



#navbar {
  margin-top: 56px;
  margin-bottom: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}


.slideupSection {
  display: inline-block;
  /* flex-wrap:wrap;  */
  /* position: relative; */


}

.slideupSection ::before {
  content: '';
  /* position:absolute;
  left:0;
  top:0; */
  width: 80px;
  /* height: 100%; */
  z-index: 1;


}

.slideupSection .slide-texts {
  display: inline-block;
  position: relative;
  z-index: 2;
  margin-left: 8px;
}


.slideupSection .slide-texts p span {
  display: inline-block;
  display: none;
  /* line-height: 86px; */
}

.slideupSection .slide-texts p span img {
  display: inline-block;
  /* margin-right: 12px; */
}

.slideupSection .slide-texts p span.text-in {
  display: inline-block;
  animation: textIn .3s ease;
}

.slideupSection .slide-texts p span.text-out {


  animation: textOut .3s ease;
}

@keyframes textIn {
  0% {
    opacity: 0%;
    transform: translateY(-30%)
  }

  100% {
    opacity: 100%;
    transform: translateY(0%)
  }
}

@keyframes textOut {
  0% {
    opacity: 0%;
    transform: translateY(0%);

  }

  100% {
    transform: translateY(30%);
    opacity: 100%;
  }
}

@media (min-width: 768px) {
  .projectsContainer {
    grid-template-columns: repeat(2, 1fr);
  }

}

@media (max-width: 768px) {

  .body {
    margin-left: 24px;
    margin-right: 24px;
  }

  .projectsContainer {
    grid-template-columns: 1fr;
    /* 1 column that takes up 100% width */
  }
}

.buttonRegular {
  font-family: 'Manrope';
  font-weight: 400;
  background-color: rgb(0, 0, 0, 0.05);
  border-radius: 32px;
  padding: 16px 24px 14px 24px !important;
  position: relative;
  text-align: center;
  font-size: 18px;
  height: fit-content;
  width:fit-content;
  border:none
}


.buttonRegular:hover {
  color: white;
  background-color: var(--primary-color);
  transition-duration: 0.2s;

}

/* Button general styling */
.btn {
  background-color: rgb(0, 0, 0, 0.05);
  border-radius: 32px;
  padding: 16px 24px 14px 24px !important;
  position: relative;
  color: var(--primary-color);
  text-align: center;
  display: flex;
}

.btn:hover {
  background-color: transparent !important;
  color: white;
}

.btn::before,
.btn::after {
  border-radius: 32px;
  background: var(--primary-color);
  content: '';
  position: absolute;
  z-index: -1;
}

/* Dark button hover */
.btn-lightmode {
  overflow: hidden;
  border: none;
}

.btn-lightmode:hover {
  background-color: transparent;
  color: white;
  /* Ensure text color changes on hover */
}


.btn-lightmode::after {
  background: var(--primary-color);
  /* Set the background color for the pseudo-element */
  color: white;
  height: 100%;
  left: -100%;
  /* Start the pseudo-element offscreen */
  top: 0px;
  transform: skew(45deg);
  transition-duration: 0.5s;
  transform-origin: top right;
  width: 100%;
}

.btn-lightmode:hover .img {
  fill: white;
}

.btn-lightmode:hover::after {
  left: 100%;
  /* Move the pseudo-element onscreen */
}


.tagRow {
  display: flex;
  gap: 4px;

}

.tag {
  padding: 12px 16px;
  margin-right: 12px;
  font-size: 16px;
  color: #333;
  border-radius: 24px;
  border: 1px solid #EFEAE3;
  display: inline-block;
  width: fit-content;
  background-color: var(--base01);
  font-family: 'Manrope';

}



.toy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  background-color: white;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 600px;
  cursor:pointer;
}

.toy:hover{
  background-color:var(--base01);
}

.socialIcon{
  width: 24px;
  transition: all 0.2s;
}
.socialIcon:hover{
  width: 28px;
}